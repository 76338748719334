import React, {useCallback, useEffect, useRef, useState} from "react";
import IconInfo from "../../icons/IconInfo";
import {Link} from "gatsby";

const TabForm = ({
    form: initialForm,
    invalidFields,
    activeProductType,
    activeProduct,
}) => {
    const showField = (field) => {
        let show = false;
        if (field.showFor) {
            if (field.showFor.length) {
                show = Boolean(field.showFor.find(product => product.includes(activeProductType.toLowerCase())));
            }
        } else {
            show = field.show;
        }
        return show;
    };

    const hintMarkDown = (field) => (
        <>
            {field.hint && (field.hint.target === '_blank'
                ? (
                    <a className="ctf__label-hint" href={field.hint.url} target={field.hint.target}>
                        <IconInfo />

                        <div className="ctf__label-tooltip">
                            {field.hint.title}
                        </div>
                    </a>
                )
                : (
                    <>
                        <Link className="ctf__label-hint" to={field.hint.url}>
                            <IconInfo />

                            <div className="ctf__label-tooltip">
                                {field.hint.title}
                            </div>
                        </Link>
                    </>
                ))
            }
        </>
    );

    const [files, setFiles] = useState([]);
    const [form, setForm] = useState(initialForm);

    const fileRef = useRef();

    const handleChange = useCallback((event) => {
        setFiles([...files, ...event.target.files]);
    }, [files]);

    const handleRemove = useCallback((name) => {
        const filteredList = files.filter(file => file.name !== name);
        const dataTransfer = new DataTransfer();
        for (let i = 0; i < fileRef.current.files.length; i++) {
            if (fileRef.current.files[i].name !== name) {
                dataTransfer.items.add(fileRef.current.files[i]);
            }
        }
        fileRef.current.files = dataTransfer.files;

        setFiles(filteredList.length ? filteredList : []);
    }, [files]);

    const inputMarkdown = useCallback((field, name, type = 'text') => {
        return (
            <>
                {showField(field) && (
                    <>
                        <label className="ctf__label ctf__label--half">
                            <p className="ctf__label-text">
                                <span>
                                    {field.label}

                                    {field.isRequired && (
                                        <span className="ctf__required-star">*</span>
                                    )}
                                </span>

                                {hintMarkDown(field)}
                            </p>

                            <input
                                className="ctf__input"
                                type={type}
                                placeholder={field.placeholder}
                                required={field.isRequired}
                                name={name}
                            />
                        </label>

                        {
                            invalidFields[name] && <div className="ctf__error-message">
                                <p>
                                    {invalidFields[name]}
                                </p>
                            </div>
                        }
                    </>
                )}
            </>
        );
    }, [form, activeProductType]);

    useEffect(() => {
        const acfFields = activeProduct && (activeProduct.acfTheme || activeProduct.acfApp);
        const updatedForm = initialForm;

        for (const propName in updatedForm) {
            if (updatedForm.hasOwnProperty(propName) && typeof updatedForm[propName] === "object") {
                updatedForm[propName].hint = null;
            }
        }

        if (acfFields && acfFields.hintsForCp) {
            acfFields.hintsForCp.forEach(hint => {
                updatedForm[hint.field].hint = hint.link;
            });
        }

        setForm(updatedForm);
    }, [activeProduct]);

    return (
        <div className="contact-tabs__form ctf">
            <h2 className="ctf__heading">
                {form.label}
            </h2>

            {form.description
                ? (
                    <p className="ctf__description">
                        {form.description}
                    </p>
                )
                : null
            }

            {form.name && inputMarkdown(form.name, 'full_name')}
            {form.email && inputMarkdown(form.email, 'email', 'email')}
            {form.phone && inputMarkdown(form.phone, 'phone', 'tel')}
            {form.version && inputMarkdown(form.version, 'version')}
            {form.linkToSource && inputMarkdown(form.linkToSource, 'source_link', 'url')}

            {form.message && showField(form.message) && (
                <label className="ctf__label ctf__label--full">
                    <p className="ctf__label-text">
                        <span>
                            {form.message.label}

                            {form.message.isRequired && (
                                <span className="ctf__required-star">*</span>
                            )}
                        </span>

                        {hintMarkDown(form.message)}
                    </p>

                    <textarea
                        className="ctf__input"
                        placeholder={form.message.placeholder}
                        required={form.message.isRequired}
                        rows={5}
                        name="message"
                    />
                </label>
            )}

            {files && files.length
                ? (
                    <div className="ctf__files-list">
                        {files.map((file, index) => (
                            <div className="ctf__file-row" key={index}>
                                <span>{file.name}</span>

                                <button
                                    className="ctf__remove"
                                    onClick={() => handleRemove(file.name)}
                                    type="button"
                                    aria-label="Remove file"
                                />
                            </div>
                        ))}
                    </div>
                )
                : null
            }

            <div className="ctf__actions">
                {form.addFiles && showField(form.addFiles) && (
                    <>
                        <label className="ctf__add-input">
                            {form.addFiles.label || 'Attach File'}

                            <input
                                type="file"
                                accept=".jpg, .jpeg, .png, .mp4, .avi, .webm, .webp, .pdf"
                                onChange={handleChange}
                                ref={fileRef}
                                // The messaging plugin does not support processing multiple files.
                                // However, from the front, everything is ready
                                multiple={false}
                                name="file"
                            />
                        </label>

                        <button
                            aria-label="Attach a file to your message"
                            className="ctf__add button button--transparent button--large"
                            type='button'
                            disabled={files && files.length}
                            onClick={() => fileRef.current.click()}
                        >
                            {form.addFiles.label || 'Attach File'}
                        </button>

                        {files && invalidFields.file && <p className="ctf__error-message">{invalidFields.file}</p>}
                    </>
                )}

                <button
                    className="ctf__submit button button--large"
                    type='submit'
                >
                    {form.sendButton.label || 'Send'}
                </button>
            </div>
        </div>
    );
}

export default TabForm;
