import React, {useCallback, useEffect, useState} from "react";
import SplitText from "./Animations/SplitText";
import cn from "classnames";

/**
 * @description Page Header Component
 * Can be called in different places, like the main page title
 * Can be called in different places, like the second level heading
 * @param background
 * @param backgroundOpacity
 * @param color
 * @param title
 * @param opacity
 * @param position
 * @param size
 * @param spaces
 * @param implemented
 * @param className
 * @param isGeneral
 * @returns {JSX.Element}
 * @constructor
 * @example
 * 	<PageHeader
 * 		{...pageHeader}
 *      title={pageHeader?.title || title}
 *      implemented={true}
 *      className={"section-title section-title--large blog-posts-filter__title"}
 *      isGeneral={true}
 *  />
 */
const PageHeader = ({
	background,
	backgroundOpacity,
	color,
	title,
	opacity,
	position,
	size,
	spaces,
	implemented = false,
	className,
	isGeneral = false,
}) => {
	const defaultClasses = cn(
		"section-title",
		"section-title--large",
		"section-title--black",
		"text-center",
	);
	const { top, bottom, leftright, units } = spaces || {};
	const { desktop: desktopSize, tablet: tabletSize, mobile: mobileSize } = size || {};
	const classes = cn(
		className || defaultClasses,
		(desktopSize || tabletSize || mobileSize) && 'page-header-custom',
		desktopSize && 'page-header-custom--desktop-size',
		tabletSize && 'page-header-custom--tablet-size',
		mobileSize && 'page-header-custom--mobile-size',
	);

	const [fontSizeDesktop, setFontSizeDesktop] = useState(null);
	const [fontSizeTablet, setFontSizeTablet] = useState(null);
	const [fontSizeMobile, setFontSizeMobile] = useState(null);
	const [backgroundColor, setBackgroundColor] = useState(null);
	const [textColor, setTextColor] = useState(null);
	const [spacesStyle, setSpaceStyle] = useState({});
	const [showCustomSpaces, setShowCustomSpaces] = useState(false);
	const [headerStyle, setHeaderStyle] = useState({});
	const [headerTag, setHeaderTag] = useState('h2');

	useEffect(() => {
		setHeaderTag(isGeneral ? 'h1' : 'h2');
	}, [isGeneral]);

	useEffect(() => {
		if (background) {
			const checkedBgOpacity = backgroundOpacity > 0 && backgroundOpacity <= 1
				? backgroundOpacity
				: backgroundOpacity / 100;
			const backOpacity = checkedBgOpacity ?? 1;
			const backAlpha = Math.round(backOpacity * 255).toString(16).padStart(2, '0');
			setBackgroundColor(`${background}${backAlpha}`);
		}
	}, [background, backgroundOpacity]);

	useEffect(() => {
		if (color) {
			const checkedTextOpacity = opacity > 0 && opacity <= 1
				? opacity
				: opacity / 100;
			const textOpacity = checkedTextOpacity ?? 1;
			const textAlpha = Math.round(textOpacity * 255).toString(16).padStart(2, '0');
			setTextColor(`${color}${textAlpha}`);
		}
	}, [color, opacity]);

	useEffect(() => {
		let tempSpacesStyle = {};

		if (top && top >= 0) {
			tempSpacesStyle = {
				...tempSpacesStyle,
				paddingTop: top + units,
			}
		}

		if (bottom && bottom >= 0) {
			tempSpacesStyle = {
				...tempSpacesStyle,
				paddingBottom: bottom + units,
			}
		}

		if (leftright && leftright >= 0) {
			tempSpacesStyle = {
				...tempSpacesStyle,
				paddingLeft: leftright + units,
				paddingRight: leftright + units,
			}
		}

		setShowCustomSpaces((top && top >= 0)
			|| (bottom && bottom >= 0)
			|| (leftright && leftright >= 0)
		);
		setSpaceStyle(tempSpacesStyle);
	}, [
		top,
		bottom,
		leftright,
		units,
	]);

	useEffect(() => {
		if (desktopSize) {
			setFontSizeDesktop(`${desktopSize}px`);
		}

		if (tabletSize) {
			setFontSizeTablet(`${tabletSize}px`);
		}

		if (mobileSize) {
			setFontSizeMobile(`${mobileSize}px`);
		}
	}, [
		desktopSize,
		tabletSize,
		mobileSize,
	]);

	useEffect(() => {
		let tempHeaderStyle = {};

		if (showCustomSpaces) {
			tempHeaderStyle = {
				...tempHeaderStyle,
				...spacesStyle,
			};
		}

		if (textColor) {
			tempHeaderStyle = {
				...tempHeaderStyle,
				color: textColor,
			};
		}

		if (backgroundColor) {
			tempHeaderStyle = {
				...tempHeaderStyle,
				backgroundColor,
			}
		}

		if (fontSizeDesktop) {
			tempHeaderStyle = {
				...tempHeaderStyle,
				'--font-size-desktop': fontSizeDesktop,
			}
		}

		if (fontSizeTablet) {
			tempHeaderStyle = {
				...tempHeaderStyle,
				'--font-size-tablet': fontSizeTablet,
			}
		}

		if (fontSizeMobile) {
			tempHeaderStyle = {
				...tempHeaderStyle,
				'--font-size-mobile': fontSizeMobile,
			}
		}

		tempHeaderStyle = {
			...tempHeaderStyle,
			borderRadius: implemented ? "25px" : "0px",
		}

		setHeaderStyle(tempHeaderStyle);
	}, [
		backgroundColor,
		textColor,
		spacesStyle,
		implemented,
		fontSizeDesktop,
		fontSizeTablet,
		fontSizeMobile,
		showCustomSpaces,
	]);

	const headerMarkup = useCallback((Element) => (
		<Element
			className={classes}
			style={headerStyle}
		>
			{title
				? <SplitText>
					{title}
				</SplitText>
				: null
			}
		</Element>
	), [title, classes, headerStyle]);

	return (
		<>
			{implemented
				? headerMarkup(headerTag)
				: (
					<section className={!showCustomSpaces ? "page-header" : 'page-header-custom-section'} style={{backgroundColor}}>
						{headerMarkup(headerTag)}
					</section>
				)
			}
		</>
	);
};

export default PageHeader;
