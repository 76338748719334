import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Tabs from "../../components/Tabs";
import handleize from "../../helpers/handleize";
import {TabWorkWithUs} from "../../components/Contact";
import TabProductSupport from "../../components/Contact/TabProductSupport";

const ContactPageTabs = ({ tabs: tabsData }) => {
    const {productsSupport, workWithUs, appsList, themesList} = tabsData;
    const tabButtonClass = 'contact-tabs__tab-button';
    const tabButtonActiveClass = 'contact-tabs__tab-button--active';
    const tabs = [
        {
            id: handleize(workWithUs.tabLabel),
            tabButtonContent: workWithUs.tabLabel,
            accessibilityLabel: workWithUs.tabLabel,
            tabButtonClass,
            tabButtonActiveClass,
            body: <TabWorkWithUs tab={workWithUs} />,
        },
        {
            id: handleize(productsSupport.tabLabel),
            tabButtonContent: productsSupport.tabLabel,
            accessibilityLabel: productsSupport.tabLabel,
            tabButtonClass,
            tabButtonActiveClass,
            body: <TabProductSupport tab={productsSupport} lists={{appsList, themesList}} />,
        },
    ];
    const [selectedTab, setSelectedTab] = useState(0);
    const [showTabs, setShowTabs] =useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const hash = window.location.hash.slice(1).split('?')[0];

            if (hash.includes('product')) {
                setSelectedTab(1);
            }
        }

        setShowTabs(true);
    }, []);

    const tabsMarkup = useCallback(() => (
        <Tabs
            selected={selectedTab}
            tabs={tabs}
            tabsClass="contact-tabs__header"
            bodyClass="contact-tabs__body"
        />
    ), [selectedTab]);

    return (
        <section className="contact-tabs container">
            {showTabs && tabsMarkup()}
        </section>
    );
};

ContactPageTabs.propTypes = {
    tabs: PropTypes.object,
};

export default ContactPageTabs;
