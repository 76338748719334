import { useEffect } from 'react';

const useBeaconWidget = (beaconId) => {
    const throttle = (func, delay) => {
        let throttleTimeout = null;
        let storedEvent = null;

        const throttledEvent = event => {
            storedEvent = event;
            const shouldHandleEvent = !throttleTimeout;
            if (shouldHandleEvent) {
                throttleTimeout = setTimeout(() => {
                    func(storedEvent);
                    throttleTimeout = null;
                    if (storedEvent) throttledEvent(storedEvent);
                    storedEvent = null;
                }, delay);
            }
        };

        return throttledEvent;
    };

    useEffect(() => {
        let scrollPosition = window.scrollY;

        function checkIsTouchDevice() {
            return !window.matchMedia('(hover: hover)').matches;
        }

        const onScroll = throttle(() => {
            if (document.body.style.position !== 'fixed') {
                scrollPosition = window.scrollY;
            }
        }, 100);

        function onWidgetClose() {
            const isTouchDevice = checkIsTouchDevice();

            if (isTouchDevice) {
                document.body.style.position = '';
                document.body.style.top = '';
                window.scrollTo(0, scrollPosition);
            }
        }

        function loadBeacon() {
            if (!window.Beacon) {
                window.Beacon = function(method, options, data) {
                    window.Beacon.readyQueue.push({ method, options, data });
                };
                window.Beacon.readyQueue = [];
            }
        }

        loadBeacon();

        const script = document.createElement('script');

        script.src = "https://beacon-v2.helpscout.net";
        script.async = true;

        script.onload = () => {
            if (window) {
                window.Beacon('init', beaconId);
                window.Beacon('on', 'close', onWidgetClose);
            }
        };

        const observer = new MutationObserver(() => {
            if (document.body.style.position === 'fixed' && document.body.style.top === '0px') {
                document.body.style.top = `-${scrollPosition}px`;
            }
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ['style'] });

        window.addEventListener('scroll', onScroll);

        document.body.appendChild(script);

        return () => {
            if (window && window.Beacon) {
                window.Beacon('off', 'close', onWidgetClose);
                window.Beacon('destroy');
            }
            observer.disconnect();
            window.removeEventListener('scroll', onScroll);
            document.body.removeChild(script);
        };
    }, [beaconId]);
};

export default useBeaconWidget;
