import React, {useCallback, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {AnimatePresence, motion} from 'framer-motion';
import {Tab} from './Tab';
import {fadeIn, tabsSection} from './../../animations';
import {KeyEventHandler} from '../utils';
import Key from '../../helpers/KeyCodes'

/**
 * @param tabsItems - The items that map to each Tab.
 * @param {number=} initSelected - The index of the currently selected Tab.
 * @param {function} onSelect - Optional callback invoked when a Tab becomes selected.
 * @param {string} tabsClass - Class for tabs container element
 * @param {string} bodyClass - Class for body container element
 * @param {boolean=} isAutomatic - Automatic tab opening when focus is set
 * @returns {JSX.Element}
 * @constructor
 */
const Tabs = ({
  tabs: tabsItems,
  selected: initSelected = 0,
  onSelect,
  tabsClass,
  bodyClass,
  isAutomatic = true,
}) => {
  const [selected, setSelected] = useState(initSelected);
  const [focused, setFocused] = useState(initSelected);
  const tabsRef = useRef(null);

  const handleTabClick = useCallback(
    (id) => {
      const tab = tabsItems.find((aTab) => aTab.id === id);

      if (tab == null) {
        return null;
      }

      const selectedIndex = tabsItems.indexOf(tab);
      setSelected(selectedIndex);
      setFocused(selectedIndex);
      onSelect?.(selectedIndex);
      tab.onAction?.(id);
    },
    [tabsItems, onSelect],
  );

  const setActivePrevTab = useCallback(() => {
    const currentFocusIndex = isAutomatic ? selected : focused;

    const isFirstTab = currentFocusIndex === 0;
    let targetTabIndex = currentFocusIndex;

    if (!isFirstTab) {
      for (let i = currentFocusIndex - 1; i >= 0; i--) {
        if (!tabsItems[i].disabled) {
          targetTabIndex = i;
          break;
        }
      }
    }

    if (!isFirstTab && targetTabIndex >= 0) {
      if (isAutomatic) {
        setSelected(targetTabIndex);
      }
      setFocused(targetTabIndex);
    }
  }, [selected, focused, isAutomatic, tabsItems]);

  const setActiveNextTab = useCallback(() => {
    const currentFocusIndex = isAutomatic ? selected : focused;

    const isLastTab = currentFocusIndex === tabsItems.length - 1;
    let targetTabIndex = currentFocusIndex;

    if (!isLastTab) {
      for (let i = currentFocusIndex + 1; i < tabsItems.length; i++) {
        if (!tabsItems[i].disabled) {
          targetTabIndex = i;
          break;
        }
      }
    }

    if (!isLastTab && targetTabIndex >= 0) {
      if (isAutomatic) {
        setSelected(targetTabIndex);
      }
      setFocused(targetTabIndex);
    }
  }, [selected, focused, isAutomatic, tabsItems]);

  const selectFocusedTab = useCallback(() => {
    setSelected(focused);
    focusTabID(tabsItems[focused]?.id);
  }, [focused, tabsItems]);

  useEffect(() => {
    focusTabID(tabsItems[focused]?.id);
  }, [focused, tabsItems]);

  return (
    <div className='tabs'>
      <KeyEventHandler
        keyCode={[Key.Enter, Key.Space]}
        event="keydown"
        handler={selectFocusedTab}
        targetRef={tabsRef}
      >
        <KeyEventHandler
          keyCode={[Key.LeftArrow, Key.UpArrow]}
          event="keydown"
          handler={setActivePrevTab}
          targetRef={tabsRef}
        >
          <KeyEventHandler
            keyCode={[Key.RightArrow, Key.DownArrow]}
            event="keydown"
            handler={setActiveNextTab}
            targetRef={tabsRef}
          >
            <ul
                className={cn(
                    'tabs__header',
                    tabsClass
                )}
                role="tablist"
                ref={tabsRef}
            >
              {tabsItems.map((_tab, index) => (
                <Tab
                  {..._tab}
                  key={`${index}-${_tab.id}`}
                  selected={index === selected}
                  onAction={() => handleTabClick(_tab.id)}
                />
              ))}
            </ul>
          </KeyEventHandler>
        </KeyEventHandler>
      </KeyEventHandler>

      <motion.div variants={tabsSection.slideUp}>
        <div className={cn(
            bodyClass,
            'tabs__content-container'
        )}>
          {tabsItems.map((_tab, index) => (
            <div
              className={cn(
                  'tabs__content',
                selected === index && 'tabs__content--selected',
              )}
              key={`${_tab.id}-panel`}
            >
              <AnimatePresence initial={false}>
                {selected === index ? (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={fadeIn}
                    className={_tab.bodyClass}
                    id={`panel-${_tab.id}`}
                    tabIndex={0}
                  >
                    {_tab.body}
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

const focusTabID = (tabID) => {
  const tab = document.getElementById(tabID);

  if (tab) {
    if (tab.tabIndex === -1) {
      tab.tabIndex = 0;
    }

    tab.focus({preventScroll: true});
  }
};
export default Tabs;
