import React, {useCallback, ReactNode} from 'react';
import {motion} from 'framer-motion';
import cn from 'classnames';
import {tabsSection} from '../../../animations';

/**
 * @param {ReactNode} tabButtonContent - The content of the Tab button
 * @param {string} accessibilityLabel - The accessible label for the Tab, if the name alone does not give enough context
 * @param {string} id - The unique identifier for the Tab
 * @param {function} onAction - Optional callback invoked when a merchant clicks on a Tab when it is not active
 * @param {boolean} disabled - Whether the Tab is disabled
 * @param {boolean} selected - If the Tab is selected
 * @param {string} contentClass - Class for tab element
 * @param {string} tabButtonClass - Class for tab button element
 * @param {string} tabButtonActiveClass - Class for active tab button element
 * @returns {JSX.Element}
 * @constructor
 */
export const Tab = ({
  tabButtonContent,
  accessibilityLabel,
  id,
  onAction,
  disabled,
  selected,
  contentClass,
  tabButtonClass,
  tabButtonActiveClass,
}) => {
  let tabIndex;

  if (selected) {
    tabIndex = 0;
  } else {
    tabIndex = -1;
  }

  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }
    if (!selected) {
      onAction?.(id);
    }
  }, [selected, onAction, disabled]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === ' ') {
        event.preventDefault();
        handleClick();
      }
    },
    [handleClick],
  );

  return (
    <li className={cn(contentClass, 'tabs__tab tab')} role="presentation">
      <motion.div variants={tabsSection.rollOut} className="tab__container">
        <button
          id={id}
          className={cn(
              'tab__button',
            tabButtonClass,
            selected && tabButtonActiveClass,
          )}
          tabIndex={tabIndex}
          aria-selected={selected}
          aria-controls={`panel-${id}`}
          aria-label={accessibilityLabel}
          role="tab"
          disabled={disabled}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          {tabButtonContent}
        </button>
      </motion.div>
    </li>
  );
};

Tab.displayName = 'Tab';
