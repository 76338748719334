import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import PageHeader from '../components/PageHeader';
import DefaultHelmet from '../seo/DefaultHelmet';
import beaconWidgetIds from '../settings/beacon-widget-ids';
import useBeaconWidget from '../hooks/useBeaconWidget';
import ContactPageTabs from '../sections/Contact/ContactPageTabs';

const ContactPage = ({pageContext}) => {
  const {
    title,
    acfContactPage,
    pageHeader,
    appsList,
    themesList,
  } = pageContext;
  const tabs = acfContactPage?.tabs;
  tabs.appsList = appsList;
  tabs.themesList = themesList;
  const pageTitle = acfContactPage?.metaData?.title || title;
  const pageDescription = acfContactPage?.metaData?.description || 'Get in touch with us at UTD! We\'re available via email, or direct message. Your queries and feedback are valuable to us. Contact us today.';
  const pageKeywords = 'web development, UTD, contact form, web development company, web design, website development';

  if (!pageHeader?.title) {
    pageHeader.title = title;
  }

  useBeaconWidget(beaconWidgetIds.GENERAL);

  return (
    <DefaultLayout className="dark-theme">
      <DefaultHelmet title={pageTitle} description={pageDescription} keywords={pageKeywords}/>

      <PageHeader {...pageHeader} isGeneral={true}/>

      {tabs && <ContactPageTabs tabs={tabs}/>}
    </DefaultLayout>
  );
};

export default ContactPage;
