import React, {useState} from "react";
import handleize from "../../helpers/handleize";
import cn from "classnames";
import {AnimatePresence, motion} from "framer-motion";

const TabWorkWithUsCategory = ({ category, required = false }) => {
    let {
        categoryValuesType,
        description,
        label,
        hiddenValues,
        type: values,
    } = category;
    const [showValues, setShowValues] = useState(!hiddenValues);

    return (
        <div className="contact-tabs__category ctc">
            <div className={cn(
                "ctc__label",
                !hiddenValues && "ctc__label--centered"
            )}>
                <label className="ctc__label-switch">
                    <h2 className="ctc__label-text">
                        {label}

                        {required && (
                            <span className="ctc__label-star">*</span>
                        )}
                    </h2>

                    <input
                        type="checkbox"
                        required={required}
                        disabled={!hiddenValues}
                        onChange={(event) => setShowValues(event.target.checked)}
                    />

                    {hiddenValues && <div className="ctc__label-slider" />}
                </label>
            </div>

            {description
                ? (
                    <div className={cn(
                        "ctc__description",
                        !hiddenValues && "ctc__description--centered"
                    )}>
                        {description}
                    </div>
                )
                : null
            }

            <AnimatePresence>
                {showValues
                    ? (
                        <motion.div
                            className="ctc__values-animate"
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            transition={{ duration: 0.8, delay: 0.1 }}
                            variants={{
                                visible: { opacity: 1, height: 'auto', marginTop: '1.5rem' },
                                hidden: { opacity: 0, height: 0, marginTop: 0 },
                                exit: { opacity: 0, height: 0, marginTop: 0 },
                            }}
                        >
                            <ul className="ctc__values">
                                {values.map((value, index) => {
                                    return (
                                        <li className="ctc__value" key={index}>
                                            <label className="ctc__value-card">
                                                <p className="ctc__value-label">
                                                    {value.label}
                                                </p>

                                                {value.description
                                                    ? (
                                                        <p className="ctc__value-description">
                                                            {value.description}
                                                        </p>
                                                    )
                                                    : null
                                                }

                                                <input
                                                    type={categoryValuesType}
                                                    name={handleize(label).replaceAll('-', '_')}
                                                    value={value.label}
                                                    required={required}
                                                />

                                                <div className={cn(
                                                    "ctc__value-button",
                                                    `ctc__value-button--${categoryValuesType}`,
                                                )} />
                                            </label>
                                        </li>
                                    )
                                })}
                            </ul>
                        </motion.div>
                    )
                    : null
                }
            </AnimatePresence>
        </div>
    );
}

export default TabWorkWithUsCategory;
