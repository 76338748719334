import React, {useCallback, useRef, useState} from "react";
import {TabWorkWithUsCategory, TabForm} from "./index";
import IconSuccess from "../../icons/IconSuccess";
import Separator from "../Separetor/Separator";

const SEPARATOR_COLOR = '#454545';

const TabWorkWithUs = ({ tab }) => {
    const {
        design,
        development,
        form,
        marketing,
        projectBudget,
        tabLabel,
        typeOfProject,
    } = tab;
    const [isSubmit, setIsSubmit] = useState(false);
    const [submitMessage, setSubmitMessage] = useState("");
    const [invalidFields, setInvalidFields] = useState({});


    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();

        const form = event.target;
        const formData = new FormData(form);
        const formDataUnique = new FormData();
        const uniqueValues = {};

        formData.forEach((value, key) => {
            if (uniqueValues[key]) {
                uniqueValues[key] += `, ${value}`;
            } else {
                uniqueValues[key] = value;
            }
        });

        for (const key in uniqueValues) {
            formDataUnique.append(key, uniqueValues[key]);
        }

        try {
            const response = await fetch("/wp-json/contact-form-7/v1/contact-forms/8761/feedback", {
                body: formDataUnique,
                method: "POST"
            });
            const responseJSON = await response.json();

            if (!responseJSON.status) {
                console.log(`Error: ${responseJSON.message}`);
                throw new Error("Server error");
            }

            setSubmitMessage(responseJSON?.message);

            if (responseJSON.status !== "mail_sent") {
                const invalidMessages = responseJSON.invalid_fields.reduce((obj, currentValue) => {
                    obj[currentValue.into.replace("span.wpcf7-form-control-wrap.", "")] = currentValue.message;

                    return obj;
                }, {});

                setInvalidFields(invalidMessages);
                return;
            }

            setInvalidFields({});
            setIsSubmit(true);
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    }, []);

    return (
        <>
            {isSubmit
                ? (
                    <div className="contact-tabs__tab contact-tabs__tab--success">
                        <IconSuccess /><p className="contact-tabs__success">{submitMessage}</p>
                    </div>
                )
                : (
                    <form className="contact-tabs__tab" onSubmit={handleSubmit}>
                        <TabWorkWithUsCategory category={typeOfProject} required={true} />
                        <Separator color={SEPARATOR_COLOR} />
                        <TabWorkWithUsCategory category={design} />
                        <Separator color={SEPARATOR_COLOR} />
                        <TabWorkWithUsCategory category={development} />
                        <Separator color={SEPARATOR_COLOR} />
                        <TabWorkWithUsCategory category={marketing} />
                        <Separator color={SEPARATOR_COLOR} />
                        <TabWorkWithUsCategory category={projectBudget} required={true} />
                        <Separator color={SEPARATOR_COLOR} />

                        <TabForm form={form} invalidFields={invalidFields} />
                    </form>
                )
            }
        </>
    );
}

export default TabWorkWithUs;
