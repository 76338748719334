import React, {useCallback, useEffect, useState} from "react";
import IconSuccess from "../../icons/IconSuccess";
import {TabForm} from "./";
import handleize from "../../helpers/handleize";
import {AnimatePresence, motion} from "framer-motion";
import Separator from "../Separetor/Separator";

const themesSupportId = 8763;
const appsSupportId = 8762;
const SEPARATOR_COLOR = '#454545';
const DEFAULT_SUBMIT_MESSAGE = "Thank you for your message. It has been sent.";

const TabProductSupport = ({ tab, lists }) => {
    const {
        addFileS,
        appsLabel,
        description,
        email,
        label,
        linkToSource,
        message,
        name,
        phone,
        sendButton,
        tabLabel,
        themesLabel,
        version,
    } = tab;
    const {appsList, themesList} = lists;
    const [isSubmit, setIsSubmit] = useState(false);
    const [submitMessage, setSubmitMessage] = useState(DEFAULT_SUBMIT_MESSAGE);
    const [productType, setProductType] = useState(themesLabel);
    const [invalidFields, setInvalidFields] = useState({});
    const [initialSelectedProductType, setInitialSelectedProductType] = useState(null);
    const [initialSelectedProduct, setInitialSelectedProduct] = useState(null);
    const [showLists, setShowLists] = useState(false);
    const [activeProduct, setActiveProduct] = useState(null);
    const form = {
        addFiles: addFileS,
        description,
        email,
        label,
        linkToSource,
        message,
        name,
        phone,
        sendButton,
        version,
    }

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();

        const form = event.target;
        const formData = new FormData(form);
        const formDataUnique = new FormData();
        const uniqueValues = {};

        formData.forEach((value, key) => {
            if (uniqueValues[key]) {
                uniqueValues[key] += `, ${value}`;
            } else {
                uniqueValues[key] = value;
            }
        });

        for (const key in uniqueValues) {
            formDataUnique.append(key, uniqueValues[key]);
        }

        const formId = formDataUnique.get('product_type') === 'theme'
            ? themesSupportId
            : appsSupportId;

        try {
            const response = await fetch(`/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`, {
                body: formDataUnique,
                method: "POST"
            });
            const responseJSON = await response.json();

            if (!responseJSON.status) {
                console.log(`Error: ${responseJSON.message}`);
                throw new Error("Server error");
            }

            setSubmitMessage(responseJSON?.message);

            if (responseJSON.status !== "mail_sent") {
                const invalidMessages = responseJSON.invalid_fields.reduce((obj, currentValue) => {
                    obj[currentValue.into.replace("span.wpcf7-form-control-wrap.", "")] = currentValue.message;

                    return obj;
                }, {});

                setInvalidFields(invalidMessages);
                return;
            }

            setInvalidFields({});
            setIsSubmit(true);
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    }, []);

    const productListMarkdown = useCallback((list, type, defaultChecked = false) => (
        <div className="cts__product-type">
            <label className="cts__label-switch">
                <h2 className="cts__label-text">{type}</h2>

                <input
                    type="radio"
                    name="product_type"
                    value={handleize(type)}
                    onClick={() => {
                        setProductType(type);
                        setActiveProduct(null);
                        setInitialSelectedProduct(null);
                    }}
                    required={true}
                    defaultChecked={defaultChecked}
                />

                <div className="cts__label-slider" />
            </label>

            <AnimatePresence>
                {productType === type && (
                    <motion.div
                        className="cts__list-animate"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.8, delay: 0 }}
                        variants={{
                            visible: { opacity: 1, height: 'auto', marginTop: '1.5rem' },
                            hidden: { opacity: 0, height: 0, marginTop: 0 },
                            exit: { opacity: 0, height: 0, marginTop: 0 },
                        }}
                    >
                        <ul className={`cts__list`}>
                            {list && list.map((product, index) => (
                                <li className="cts__list-item" key={index}>
                                    <label className="cts__list-item-card">
                                        <h2 className="cts__list-item-label">
                                            {product.title}
                                        </h2>

                                        <input
                                            type="radio"
                                            name={handleize(productType)}
                                            value={product.title}
                                            required={true}
                                            defaultChecked={product.slug === initialSelectedProduct}
                                            onChange={event => {
                                                if (event.target.checked) {
                                                    setActiveProduct(product);
                                                }
                                            }}
                                        />

                                        <div className="cts__radio" />

                                        <div className="cts__list-item-description">
                                            {product.acfApp?.descriptionForCp}
                                            {product.acfTheme?.descriptionForCp}
                                        </div>
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    ), [productType, initialSelectedProductType, initialSelectedProduct]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const searchParams = new URLSearchParams(window.location.search);
            const app = searchParams.get('app');
            const theme = searchParams.get('theme');
            const activeSlug = theme ?? app;

            if (activeSlug) {
                const summaryList = [
                    ...appsList,
                    ...themesList,
                ];
                const activeProduct = summaryList.find(({slug}) => slug === activeSlug);
                setActiveProduct(activeProduct);
            }

            if (theme) {
                setInitialSelectedProductType(themesLabel);
                setInitialSelectedProduct(theme);
                setProductType(themesLabel);
            }

            if (app) {
                setInitialSelectedProductType(appsLabel);
                setInitialSelectedProduct(app);
                setProductType(appsLabel);
            }
        }

        setShowLists(true);
    }, []);

    return (
        <>
            {isSubmit
                ? (
                    <div className="contact-tabs__tab contact-tabs__tab--success">
                        <IconSuccess /><p className="contact-tabs__success">{submitMessage}</p>
                    </div>
                )
                : (
                    <div className="contact-tabs__tab">
                        <form className="contact-tabs__support cts" onSubmit={handleSubmit}>
                            {showLists && (
                                <>
                                    {productListMarkdown(themesList, themesLabel, initialSelectedProductType ? initialSelectedProductType === themesLabel : true)}
                                    <Separator color={SEPARATOR_COLOR} />
                                    {productListMarkdown(appsList, appsLabel, initialSelectedProductType ? initialSelectedProductType === appsLabel : false)}
                                    <Separator color={SEPARATOR_COLOR} />
                                </>
                            )}

                            <TabForm
                                form={form}
                                invalidFields={invalidFields}
                                activeProductType={productType}
                                activeProduct={activeProduct}
                            />
                        </form>
                    </div>
                )
            }
        </>
    );
}

export default TabProductSupport;
