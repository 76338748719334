import React from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import cn from "classnames";

const SplitText = ({ children, generalDuration = 0.8, generalDelay = 0, wordDelay = 0.1, ...rest }) => {
	const words = children.split(" ");

	return (
		<>
			{words.map((word, index) => {
				const isGradientWord = word.startsWith('%g%');
				let renderWord = word;

				if (isGradientWord) {
					renderWord = word.substring(3);
				}

				return (
					<span
						key={index}
						className="split-text"
					>
						<motion.span
							className={cn(
								"split-text__word",
								isGradientWord && "gradient-text"
							)}
							initial={{
								y: "0",
								opacity: 0
							}}
							animate="visible"
							variants={{
								visible: index => ({
									y: 0,
									opacity: 1,
									transition: {
										duration: generalDuration,
										delay: generalDelay + index * wordDelay,
										ease: "easeOut"
									}
								})
							}}
							custom={index}
							{...rest}
						>
							{renderWord + (index !== words.length - 1 ? "\u00A0" : "")}
						</motion.span>
					</span>
				)
			})}
		</>
	);
}

SplitText.propTypes = {
	generalDuration: PropTypes.number,
	generalDelay: PropTypes.number,
	wordDelay: PropTypes.number
};

export default SplitText;
